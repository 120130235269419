// App main
import '/src/css/app.pcss';
import StatusMessage from './oscar-status-messages';

let statusMessages = [];
let statusMessageElement = document.querySelector('[data-status-messages]');
if (statusMessageElement) {
    let statusMessagesContent = JSON.parse(statusMessageElement.getAttribute('data-status-messages'));

    if (statusMessagesContent.length) {
        let arrayLength = statusMessagesContent.length;
        for (let i = 0; i < arrayLength; i++) {
            statusMessages.push(new StatusMessage(statusMessagesContent[i].message, statusMessagesContent[i].type));
        }
    }
}

// Mobile screen height fix
const screenHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--screen-height', `${window.innerHeight}px`);
}
window.addEventListener('resize', screenHeight);
screenHeight();

// Navigation
(function() {
    'use strict';
    // get button and menu nodes
    const button = document.querySelector('#nav-button');
    const menu = document.querySelector('#nav-container ul');
    const menuContainer = document.querySelector('#nav-container');
    if (menu) {
        // set initial (closed menu) states
        button.setAttribute('aria-expanded', 'false');
        button.hidden = false;
        menu.hidden = true;
        if (!menu.querySelectorAll('li').length) {
            console.log('no menu items');
            button.hidden = true;
        }
        button.addEventListener('click', function() {
            // toggle menu visibility
            const expanded = this.getAttribute('aria-expanded') === 'true';
            this.setAttribute('aria-expanded', String(!expanded));
            menu.hidden = expanded;
            menuContainer.classList.toggle('is-open');
        });
    }
})();

// Cookie helper functions
let cookieHelper = (function() {
    'use strict';

    /* Variables */
    let publicAPIs = {};

    /* Methods */
    /**
     * Create cookie
     */
    publicAPIs.createCookie = function(name, value, days, sameSite = null) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        let cookie = name + "=" + value + expires + "; path=/ ;domain=" + window.location.hostname;
        if (sameSite) {
            cookie += `;SameSite=${sameSite}`;
        }
        document.cookie = cookie;
    };

    /**
     * Read cookie
     */
    publicAPIs.readCookie = function(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    };

    /**
     * Erase cookie
     */
    publicAPIs.eraseCookie = function(name) {
        publicAPIs.createCookie(name, "", -1);
    };

    /* Return the Public APIs */
    return publicAPIs;
})();

// Global notice control
(function() {
    'use strict';
    // get notice and close button nodes
    const closeButton = document.querySelector('#notice-close');
    const noticeContainer = document.querySelector('#notice');
    const noticeCookie = cookieHelper.readCookie('hide-notice');

    // if no notice exists then delete cookie to make sure next notice is shown
    if (!noticeContainer) {
        cookieHelper.eraseCookie('hide-notice');
    } else {
        // hide notice if cookie is set to 'true'
        if (noticeCookie === 'true' && closeButton) {
            noticeContainer.hidden = true;
        }

        // if close button exists, listen for it
        if (closeButton) {
            closeButton.addEventListener('click', function() {
                // close notice on click
                noticeContainer.hidden = true;
                cookieHelper.createCookie('hide-notice', 'true', 28, 'Strict');
            });
        }
    }
})();

// Lazy load embedded YouTube videos
(function() {

    let youtube = document.querySelectorAll(".youtube");

    for (var i = 0; i < youtube.length; i++) {

        var source = "https://img.youtube.com/vi/" + youtube[i].dataset.embed + "/sddefault.jpg";

        var image = new Image();
        image.src = source;
        image.addEventListener("load", function() {
            youtube[i].appendChild(image);
        }(i));

        youtube[i].addEventListener("click", function() {

            var iframe = document.createElement("iframe");

            iframe.classList.add('w-full', 'h-full');

            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.embed + "?enablejsapi=1&rel=0&showinfo=0&autoplay=1");

            this.innerHTML = "";
            this.appendChild(iframe);

            if (typeof _paq !== 'undefined') {
                _paq.push(['MediaAnalytics::scanForMedia', this]);
            }
        });
    }
})();